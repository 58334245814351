import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider } from '../../../components/hook-form';
import { loginUser } from 'src/api/auth/login';
import useLocalStorage from 'src/hooks/useLocalStorage';
import Typography from 'src/theme/overrides/Typography';

// ----------------------------------------------------------------------

export default function LoginForm() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useLocalStorage('token', null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthStatus = async () => {
      await token;
      if (token) {
        navigate('/');
      } else {
        return;
      }
    };
    checkAuthStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: ''
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const onSubmit = async (e) => {
    e.preventDefault()

    await loginUser({ email, password, setToken, setError })
    navigate('/')


  };

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={3}>
        <TextField
          required
          id="filled-search"
          type="email"
          label="Email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          required
          id="filled-search"
          type="password"
          label="Password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Stack>

      {error?.length > 0 && (
        <p style={{ color: 'red' }} >
          {error}
        </p>
      )}

      <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ my: 2 }}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
