/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Box, Button, Stack } from '@mui/material';
import { deleteChaletImage, getSingleChalet, uploadChaletImages, chooseDefaultChaletImage } from 'src/api/chalets';
import Iconify from 'src/components/Iconify';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const Header = styled.h1`
  font-size: 20px;
`;

const ImagesContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  gap: 5px;
`;

const ImgContainer = styled.div`
  position: relative;
  flex: 1;

  img {
    height: 100%;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #808080a0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    color: #f80808;
    z-index: 999;
    width: 20px;
    height: 20px;
  }
`;

const DefaultIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #808080a0;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    color: #f80808;
    z-index: 999;
    width: 90px;
    height: 90px;
  }
`;

const AddLabel = styled.div`
  cursor: pointer;
  height: 15rem;
  width: 15rem;
  border-radius: 10px;
  overflow: hidden;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // border: 2px solid $color-very-smooth-gray;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.103);
  padding: 1rem;
  transition: all ease-in-out 0.2s;

  &:active {
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.103);
  }

  &__text {
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }
`;

const AddImages = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [chaletImages, setChaletImages] = useState([]);
  const [imgUploaded, setImgUploaded] = useState([]);
  const [imgSelected, setImgSelected] = useState([]);
  const [imgDefaultUploaded, setImgDefaultUploaded] = useState([]);
  const [imgDefaultSelected, setImgDefaultSelected] = useState([]);

  useEffect(() => {
    if (id) {
      fetchChaletData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchChaletData = () => {
    getSingleChalet(id).then((res) => {
      console.log(res.data.data.chalet.images);
      setChaletImages(res.data.data.chalet.images);
    });
  };

  const uploadImgChangeHandler = (e) => {
    if (e.target.files.length > 0) {
      let index = e.target.files.length;
      let files = e.target.files;
      let arrBeforSet = [...imgUploaded];
      let arrToPushBeforSet = [...imgSelected];
      for (let i = 0; i < index && i < 10 - imgUploaded.length; i++) {
        let imgSrc = URL.createObjectURL(files[i]);
        arrBeforSet.push(imgSrc);
        arrToPushBeforSet.push(files[i]);
      }
      setImgUploaded(arrBeforSet);
      setImgSelected(arrToPushBeforSet);
    }
  };

  function delImg(i) {
    let arr = [];
    let newArr = [...imgUploaded];
    newArr.map(function (el, index) {
      if (index !== i) {
        arr.push(el);
      }
    });
    setImgUploaded(arr);
    let arrS = [];
    let newArrS = [...imgSelected];
    newArrS.map(function (el, index) {
      if (index !== i) {
        arrS.push(el);
      }
    });
    setImgSelected(arrS);
  }


  const deleteExistingImage = (imgSrc) => {
    deleteChaletImage(imgSrc.src, id).then((res) => {
      fetchChaletData();
    });
  };


  const chooseDefaultImage = (imgSrc) => {
    console.log(imgSrc);
    console.log(id);
    chooseDefaultChaletImage(imgSrc.src, id).then((res) => {
      fetchChaletData();
    });
  };

  const submitImages = () => {
    const formData = new FormData();
    formData.append('chaletId', id);
    imgSelected.forEach((img) => {
      formData.append('images', img);
    });
    uploadChaletImages(formData).then(() => {
      navigate(`/dashboard/chalet/${id}`);
    });
  };

  return (

    <Container>
      <Header>Chalet Gallery</Header>

      <ImagesContainer>
        {chaletImages.map((img) => (
          <ImgContainer>
            <IconContainer onClick={() => deleteExistingImage(img)}>
              <Iconify icon="ant-design:delete-filled" />
            </IconContainer>
            <IconContainer onClick={() => chooseDefaultImage(img)} style={{ left: "0px" }}>
              <Iconify icon="eos-icons:master" style={{ color: 'yellow' }} />
            </IconContainer>
            {img.defaultImage
              ? <DefaultIconContainer style={{ left: "50%", top: "50%", transform: "translate(-50%,-50%)" }}>
                <Iconify icon="eos-icons:master" style={{ color: 'yellow' }} />
              </DefaultIconContainer>
              : <></>}
            <img src={img.src} />
          </ImgContainer>
        ))}
      </ImagesContainer>

      <h3>Or Upload New photos for your chalet</h3>
      {imgUploaded && imgUploaded.length > 0 ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', marginBottom: '4rem' }}>
          {imgUploaded.map((img, index) => {
            return (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '15rem',
                  width: '15rem',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  margin: '1rem',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyVontent: 'center',
                    height: '3rem',
                    width: '3rem',
                    top: '3%',
                    right: '3%',
                    cursor: 'pointer',
                    transition: 'all ease-in-out 0.15s',
                    backgroundColor: 'rgba(255, 255, 255, 0.692)',
                    borderRadius: '50%',
                  }}
                  onClick={() => delImg(index)}
                >
                  <h4>Cancel</h4>
                </Box>
                <img src={img} />
              </Stack>
            );
          })}
          {imgUploaded && imgUploaded.length < 10 ? (
            <label htmlFor="add-more-imgs-about-chalet-from-owner">
              {/* <Iconify icon="ant-design:camera-filled" /> */}
              <AddLabel>Upload photo</AddLabel>
              <input
                multiple={true}
                type="file"
                accept="image/*"
                id="add-more-imgs-about-chalet-from-owner"
                onChange={uploadImgChangeHandler}
                style={{ display: 'none' }}
              />
            </label>
          ) : null}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <label htmlFor="add-first-img-about-chalet-from-owner">
            <Box
              sx={{
                height: '5rem',
                width: '5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              <Iconify icon="eva:plus-fill" />
              <input
                multiple={true}
                type="file"
                accept="image/*"
                id="add-first-img-about-chalet-from-owner"
                onChange={uploadImgChangeHandler}
                style={{ display: 'none' }}
              />
            </Box>
          </label>
        </Box>
      )}
      {imgSelected?.length > 0 && (
        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={submitImages}>
          Submit New Images
        </Button>
      )}
    </Container>
  );
};

export default AddImages;
