import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addChaletLocker, editChaletLocker, deleteChaletLocker, addDynamicPrice, addOrEditChalet, editChaletStatus, getChaletLockers, getSingleChalet, getDynamicPrice, editChaletActivity } from 'src/api/chalets';
import { getChaletTypes } from 'src/api/chaletTypes';
import { getCities } from 'src/api/cities';
import { getResorts } from 'src/api/resorts';
import Iconify from 'src/components/Iconify';
import Gallery from 'src/components/shared/Gallery';
import { mapChaletUiToData } from 'src/utils/chalet';
import { mapDataToUi } from './mapDataToUi';
import TableHeader from 'src/components/shared/Table/TableHeader';
import ModalComponent from 'src/components/shared/Modal';
import { getRules } from 'src/api/rules';
import { getFacilities } from 'src/api/facilities';
import { getChaletViews } from 'src/api/views';
import { capitalizeString } from 'src/utils/formatString';
import SwitchToggle from 'src/SwitchToggle';

const LOCKERS_HEADER = [
  { id: 'from', label: 'From', alignRight: false },
  { id: 'to', label: 'To', alignRight: false },
  { id: 'comment', label: 'Comment', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false },
];
// add actions column materialui for LOCKERS_HEADER

const DYNAMIC_PRICE_HEADER = [
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'priceType', label: 'Price Type', alignRight: false },
  { id: 'desc', label: 'Description', alignRight: false },
];

const SingleChalet = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [multiSelectData, setMultiSelectData] = useState(null);
  const [cities, setCities] = useState([]);
  const [resorts, setResorts] = useState([]);
  const [types, setTypes] = useState([]);
  const [rules, setRules] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [views, setViews] = useState([]);
  const [error, setError] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [dynamicPrice, setDynamicPrice] = useState(0);
  const [dynamicPriceDescription, setDynamicPriceDescription] = useState("");
  const [dynamicPriceDate, setDynamicPriceDate] = useState(null);
  const [dynamicPriceError, setDynamicPriceError] = useState('');
  const [dynamicPriceType, setDynamicPriceType] = useState('custom');
  const [showDynamicPriceModal, setShowDynamicPriceModal] = useState(false);
  const [chaletLockers, setChaletLockers] = useState([]);
  const [chaletDynamicPrices, setChaletDynamicPrices] = useState([]);
  const [showLockerModal, setShowLockerModal] = useState(false);
  const [lockerStartDate, setLockerStartDate] = useState(null);
  const [lockerEndDate, setLockerEndDate] = useState(null);
  const [lockerComment, setLockerComment] = useState(null);
  const [lockerEditID, setLockerEditID] = useState(null);
  const [addLockerError, setAddLockerError] = useState('');

  useEffect(() => {
    if (id) {
      Swal.showLoading()

      fetchChaletData();
      Swal.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchCities = async () => {
    const { data } = await getCities();
    setCities(data.cities);
  };

  const fetchResorts = async () => {
    const { data } = await getResorts();
    setResorts(data.resorts);
  };

  const fetchChaletTypes = async () => {
    const { data } = await getChaletTypes();
    setTypes(data.types);
  };

  const fetchChaletLockers = () => {
    getChaletLockers(id).then((res) => {
      setChaletLockers(res.data.data.lockers);
    });
  };

  const fetchDynamicPrice = () => {
    getDynamicPrice(id).then((res) => {
      setChaletDynamicPrices(res.data.data.prices);
    });
  };

  /**
   * {
    "total": 3,
    "facility": [
        {
            "_id": "6334d23841542a33187cf46c",
            "name": "1facility",
            "name_ar": "1اضافات",
            "__v": 0
        },
        {
            "_id": "6368ce9c31c50346b8275801",
            "name": "Microwave",
            "name_ar": "ميكرويف",
            "__v": 0
        },
        {
            "_id": "63b15567dada3d72495581c8",
            "name": "TV",
            "name_ar": "تلفاز",
            "__v": 0
        }
    ]
}
   */
  const fetchRules = async () => {
    const { data } = await getRules();
    setRules(data.chaletRoles);
  };

  const fetchFacilities = async () => {
    const { data } = await getFacilities();
    console.log(data)
    setFacilities(data.facility);
  };

  const fetchChaletViews = async () => {
    const { data } = await getChaletViews();
    setViews(data.views);
  };

  useEffect(() => {
    Swal.showLoading()
    fetchCities();
    fetchResorts();
    fetchChaletTypes();
    fetchChaletLockers();
    fetchDynamicPrice();
    fetchRules();
    fetchFacilities();
    fetchChaletViews()
    Swal.close()
  }, []);

  const fetchChaletData = () => {
    getSingleChalet(id).then((res) => {
      setData(mapDataToUi(res.data.data.chalet));
      setMultiSelectData(res.data.data.chalet)
      console.log(res.data.data.chalet)
    });
  };

  const handleChangeApprove = (event) => {
    Swal.showLoading()
    const { name, checked } = event.target;
    let approval_status = checked ? "approved" : "binding"
    editChaletStatus({ chalet_id: id, approval_status: approval_status })
      .then(res => {
        fetchChaletData()
        setMultiSelectData((prevState) => ({
          ...prevState,
          [name]: checked ? "approved" : "binding",
        }));
        Swal.fire('Status Changed Successfully', '', 'success')
      }).catch(err => {
        Swal.fire('Something Went Wrong', '', 'error')
      })
  }
  const handleChangeActive = (event) => {
    Swal.showLoading()
    const { name, checked } = event.target;
    let isActive = checked ? true : false
    editChaletActivity({ chaletId: id })
      .then(res => {
        // fetchChaletData()
        setMultiSelectData((prevState) => ({
          ...prevState,
          [name]: checked ? true : false,
        }));

        setData((prevState) => ({
          ...prevState,
          [name]: checked ? true : false,
        }))
        Swal.fire('Activity Changed Successfully', '', 'success')
      }).catch(err => {
        Swal.fire('Something Went Wrong', '', 'error')
      })
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // setData({
    //   ...data,
    //   [name]: value,
    // });
    setMultiSelectData((prevState) => ({
      ...prevState,
      [name]: capitalizeString(value),
    }));

    setData((prevState) => ({
      ...prevState,
      [name]: capitalizeString(value),
    }));
  };

  const handleMultiSelectChange = (values, name) => {
    let newValues = values.map((value) => value._id);

    setMultiSelectData((prevState) => ({
      ...prevState,
      [name]: values,
    }));

    setData((prevState) => ({
      ...prevState,
      [name]: newValues,
    }));
  };

  const onCancel = () => {
    setIsEdit(false);
  };

  const handleSubmit = () => {
    const editData = {
      _id: id,
      ...mapChaletUiToData(data),
    };
    addOrEditChalet(editData)
      .then(() => {
        fetchChaletData();
        setIsEdit(false);
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  const handleAddDynamicPrice = () => {
    const refactorDate = moment(dynamicPriceDate?.$d)?.format('YYYY-MM-DD');
    const data = {
      chalet: id,
      price: dynamicPrice,
      priceType: dynamicPriceType,
      date: refactorDate,
      desc: dynamicPriceDescription
    };
    Swal.showLoading()
    addDynamicPrice(data)
      .then((res) => {
        setShowDynamicPriceModal(false);
        setDynamicPrice(0);
        setDynamicPriceType('custom');
        setDynamicPriceDescription('');
        setDynamicPriceDate(null);
        setDynamicPriceError('');
        fetchDynamicPrice();

      }).then(res => {
        Swal.fire("Dynamic Price Added", '', 'success')
      })
      .catch((err) => {
        Swal.fire(err.response.data.message ?? "Failed", '', 'error')
        setDynamicPriceError(err.response.data.message);
      });
  };

  const onAddLocker = () => {
    const data = {
      chalet_id: id,
      start_date: moment(lockerStartDate?.$d)?.format('YYYY-MM-DD'),
      end_date: moment(lockerEndDate?.$d)?.format('YYYY-MM-DD'),
      comment: lockerComment,
    };
    Swal.showLoading()
    addChaletLocker(data)
      .then((res) => {
        fetchChaletLockers();
        setShowLockerModal(false);
        setAddLockerError('');
        setLockerStartDate(null);
        setLockerEndDate(null);
        setLockerComment('');
        setLockerEditID(null)
      }).then(res => {
        Swal.fire('Locker Added Succesfully', '', 'success')
      })
      .catch((err) => {
        Swal.fire(err.response.data.message ?? "Failed", '', 'error')
        setAddLockerError(err.response.data.message);
      });
  };

  const onEditLocker = () => {
    const data = {
      lockerId: lockerEditID,
      start_date: moment(lockerStartDate)?.format('YYYY-MM-DD'),
      end_date: moment(lockerEndDate)?.format('YYYY-MM-DD'),
      comment: lockerComment,
    };

    console.log(data);
    editChaletLocker(data)
      .then((res) => {
        fetchChaletLockers();
        setShowLockerModal(false);
        setAddLockerError('');
        setLockerStartDate(null);
        setLockerEndDate(null);
        setLockerComment('');
        setLockerEditID(null)
      }).then(res => {
        Swal.fire('Locker Edited Succesfully', '', 'success')
      })
      .catch((err) => {
        Swal.fire(err.response?.data.message ?? "Failed", '', 'error')

        setAddLockerError(err.response?.data.message);
      });
  };

  const onDeleteLocker = (lockerId) => {
    const data = {
      lockerId: lockerId,
    };


    Swal.fire({
      title: 'Are you sure?',
      text: "You will delete this locker",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteChaletLocker(data)
          .then((res) => {
            fetchChaletLockers();
            setShowLockerModal(false);
            setAddLockerError('');
            setLockerStartDate(null);
            setLockerEndDate(null);
            setLockerComment('');
            setLockerEditID(null)
          }).then(res => {
            Swal.fire('Locker Deleted Succesfully', '', 'success')
          })
          .catch((err) => {
            Swal.fire(err.response.data.message ?? "Failed", '', 'error')
            setAddLockerError(err.response.data.message);
          });
      }
    })


  };

  const routeToChaletImages = () => {
    navigate(`/dashboard/chalet/${id}/images`);
  };

  const refactorDateWithTime = (date) => {
    return date.split('T')[0];
  };

  // write function that converts this format "2023-04-28T00:00:00.000Z" to datepicker format
  // const refactorDate = (date) => {

  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          {data?.title}
        </Typography>
        <div>
          <InputLabel id="demo-simple-select-autowidth-label">Approval Status</InputLabel>
          <FormControlLabel
            control={<SwitchToggle sx={{ m: 1 }}
              checked={data?.approval_status === "approved"}
              onChange={handleChangeApprove}
              name="approval_status" />}
            label={data?.approval_status === "approved" ? "Approved" : "Pending"}
            // label="Active"
            disabled={!isEdit}
          />
        </div>

        <div>
          <InputLabel id="demo-simple-select-autowidth-label">Activity</InputLabel>
          <FormControlLabel
            control={<SwitchToggle sx={{ m: 1 }}
              checked={data?.isActive == true}
              onChange={handleChangeActive}
              name="isActive" />}
            label={data?.isActive ? "Active" : "Inactive"}
            // label="Active"
            disabled={!isEdit}
          />
        </div>
        {!isEdit && (
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setIsEdit(true)}>
            Edit Chalet
          </Button>
        )}
        <Button
          variant="contained"
          startIcon={<Iconify icon="ant-design:camera-outlined" />}
          onClick={routeToChaletImages}
        >
          Edit Images
        </Button>
      </Stack>
      {data?.images?.length > 0 && !isEdit && <Gallery images={data?.images} />}
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
          overFlow: 'scroll',
          padding: '10px',
        }}
        noValidate
        autoComplete="off"
      >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {/* <TextField
          required
          id="filled-search"
          type="text"
          label="Name Arabic"
          name="nameAr"
          value={formValues.nameAr}
          onChange={handleChange}
        /> */}

          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Title</InputLabel>
            <TextField
              required
              id="filled-search"
              type="text"
              name="title"
              value={data?.title}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Chalet Code</InputLabel>
            <TextField
              required
              id="filled-search"
              type="text"
              name="title"
              value={data?.code}
              disabled
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Title Arabic</InputLabel>
            <TextField
              required
              id="filled-search"
              type="text"
              name="titleAr"
              value={data?.titleAr}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          {/* <div>
            <InputLabel id="demo-simple-select-autowidth-label">Name English</InputLabel>
            <TextField
              required
              id="filled-search"
              type="text"
              name="nameEn"
              value={data?.nameEn}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div> */}
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Location</InputLabel>
            <TextField
              required
              id="filled-search"
              type="text"
              name="location"
              value={data?.location}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Street</InputLabel>
            <TextField
              required
              id="filled-search"
              type="text"
              name="street"
              value={data?.street}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Building</InputLabel>
            <TextField
              required
              id="filled-search"
              type="text"
              name="building"
              value={data?.building}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Unit Number</InputLabel>
            <TextField
              required
              id="filled-search"
              type="text"
              name="unitNumber"
              value={data?.unitNumber}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Latitude</InputLabel>
            <TextField
              required
              id="filled-search"
              type="number"
              name="latitude"
              value={data?.lat}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Longtude</InputLabel>
            <TextField
              required
              id="filled-search"
              type="number"
              name="longtude"
              value={data?.long}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Discount</InputLabel>
            <TextField
              required
              id="filled-search"
              type="number"
              name="discount"
              value={data?.discount}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Markup</InputLabel>
            <TextField
              required
              id="filled-search"
              type="number"
              name="markup"
              value={data?.markup}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Price</InputLabel>
            <TextField
              required
              id="filled-search"
              type="number"
              name="price"
              value={data?.price}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Area</InputLabel>
            <TextField
              required
              id="filled-search"
              type="number"
              name="area"
              value={data?.area}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Rooms Number</InputLabel>
            <TextField
              required
              id="filled-search"
              type="number"
              name="roomsNumber"
              value={data?.roomsNumber}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Beds Number</InputLabel>
            <TextField
              required
              id="filled-search"
              type="number"
              name="bedsNumber"
              value={data?.bedsNumber}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Bathrooms Number</InputLabel>
            <TextField
              required
              id="filled-search"
              type="number"
              name="bathroomsNumber"
              value={data?.bathroomsNumber}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Floors Number</InputLabel>
            <TextField
              required
              id="filled-search"
              type="number"
              name="floorsNumber"
              value={data?.floorsNumber}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Units Number</InputLabel>
            <TextField
              required
              id="filled-search"
              type="number"
              name="unitsNumber"
              value={multiSelectData?.unit_number}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Capacity</InputLabel>
            <TextField
              required
              id="filled-search"
              type="number"
              name="capacity"
              value={data?.capacity}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </div>
          <div>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={rules}
              getOptionLabel={(option) => option.name}
              // defaultValue={data?.rules}
              value={multiSelectData?.roles.length ? [...multiSelectData.roles] : []}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} label="Rules" placeholder="Rules" />}
              onChange={(e, value) => handleMultiSelectChange(value, 'roles')}
              disabled={!isEdit}
            />
          </div>
          <div>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={facilities}
              getOptionLabel={(option) => option.name}
              // defaultValue={multiSelectData?.facilities}
              // value={multiSelectData?.facilities}
              value={multiSelectData?.facilities.length ? [...multiSelectData.facilities] : []}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} label="Facilities" placeholder="Facilities" />}
              onChange={(e, value) => {
                handleMultiSelectChange(value, 'facilities')
              }}
              disabled={!isEdit}
            />
          </div>
          <div>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={views}
              getOptionLabel={(option) => option.name}
              // defaultValue={data?.views}
              value={multiSelectData?.views.length ? [...multiSelectData.views] : []}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} label="Views" placeholder="Views" />}
              onChange={(e, value) => handleMultiSelectChange(value, 'views')}
              disabled={!isEdit}
            />
          </div>

          {isEdit ? (
            <>
              <FormControl fullWidth sx={{ margin: '15px 0' }}>
                <InputLabel id="demo-simple-select-autowidth-label">City</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select"
                  value={data?.cityId}
                  label="City"
                  onChange={handleChange}
                  name="cityId"
                >
                  <MenuItem value="1212">
                    <em>test</em>
                  </MenuItem>
                  {cities?.map((city) => (
                    <MenuItem key={city._id} value={city._id}>
                      {city?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ marginBottom: '15px' }}>
                <InputLabel id="demo-simple-select-autowidth-label">Resort</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select"
                  value={data?.resortId}
                  label="Resort"
                  onChange={handleChange}
                  name="resortId"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {resorts?.map((resort) => (
                    <MenuItem value={resort._id}>{resort?.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-autowidth-label">Chalet Types</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select"
                  value={data?.typeId}
                  label="Chalet Type"
                  onChange={handleChange}
                  name="typeId"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {types?.map((type) => (
                    <MenuItem value={type._id}>{type?.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          ) : (
            <>
              <div>
                <InputLabel id="demo-simple-select-autowidth-label">City</InputLabel>
                <TextField id="filled-search" type="text" name="floorsNumber" value={data?.city} disabled />
              </div>
              <div>
                <InputLabel id="demo-simple-select-autowidth-label">Resort</InputLabel>
                <TextField id="filled-search" type="text" name="floorsNumber" value={data?.resort} disabled />
              </div>
              <div>
                <InputLabel id="demo-simple-select-autowidth-label">Type</InputLabel>
                <TextField id="filled-search" type="text" name="floorsNumber" value={data?.type} disabled />
              </div>
            </>
          )}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Check In"
              value={dayjs(data?.checkIn)}
              onChange={(value) => {
                setData({
                  ...data,
                  checkIn: value.$d,
                });
              }}
              renderInput={(params) => <TextField {...params} />}
              disabled={!isEdit}
            />
            <TimePicker
              label="Check Out"
              value={dayjs(data?.checkOut)}
              onChange={(value) => {
                setData({
                  ...data,
                  checkOut: value.$d,
                });
              }}
              renderInput={(params) => <TextField {...params} />}
              disabled={!isEdit}
            />
          </LocalizationProvider>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Description</InputLabel>
            <TextareaAutosize
              required
              id="filled-search"
              type="test"
              label="Description"
              name="description"
              value={data?.description}
              onChange={handleChange}
              minRows={7}
              disabled={!isEdit}
            />
          </div>
          <div>
            <InputLabel id="demo-simple-select-autowidth-label">Description Arabic</InputLabel>
            <TextareaAutosize
              required
              id="filled-search"
              type="test"
              label="Description Arabic"
              name="descriptionAr"
              value={data?.descriptionAr}
              onChange={handleChange}
              minRows={7}
              disabled={!isEdit}
            />
          </div>
          <div>
            {error?.length > 0 && isEdit && (
              <Typography sx={{ color: 'red' }} component="p">
                {error}
              </Typography>
            )}
          </div>
        </div>
        {isEdit && (
          <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px', justifyContent: 'flex-end' }}>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        )}

        {/**********************Chalet Lockers******************************/}
        <TableContainer sx={{ minWidth: 800 }}>
          <h2>Chalet Lockers</h2>
          <Table>
            <TableHeader headLabel={LOCKERS_HEADER} />
            <TableBody>
              {chaletLockers?.map((locker) => {
                const { _id, start_date, end_date, comment } = locker;
                return (
                  <TableRow key={_id}>
                    <TableCell align="left">{refactorDateWithTime(start_date)}</TableCell>
                    <TableCell align="left">{refactorDateWithTime(end_date)}</TableCell>
                    <TableCell align="left">{comment}</TableCell>
                    <TableCell align="left">
                      {/* right two buttons for edit and delete passing _id as argument */}
                      <Button onClick={() => {
                        setAddLockerError('');
                        setLockerStartDate(new Date(start_date))
                        setLockerEndDate(new Date(end_date));
                        setLockerComment(comment);
                        setLockerEditID(_id);
                        setShowLockerModal(true);
                      }}
                        variant="contained"
                        style={{ marginRight: "20px" }}
                      >Edit</Button>

                      <Button variant="contained" color='error' onClick={() => onDeleteLocker(_id)}>Delete</Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Button variant="contained" onClick={() => setShowLockerModal(true)}>
            Add Locker
          </Button>
          <ModalComponent isOpen={showLockerModal}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="MM/DD/YYYY"
                  value={lockerStartDate}
                  onChange={(date) => { console.log(date); setLockerStartDate(date) }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="End Date"
                  inputFormat="MM/DD/YYYY"
                  value={lockerEndDate}
                  onChange={(date) => setLockerEndDate(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <TextField
                required
                id="filled-search"
                type="text"
                placeholder="Comment"
                name="discount"
                value={lockerComment}
                onChange={(e) => setLockerComment(e.target.value)}
              />
              {addLockerError?.length > 0 && (
                <Typography sx={{ color: 'red' }} component="p">
                  {addLockerError}
                </Typography>
              )}
              <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px', justifyContent: 'flex-end' }}>
                {lockerEditID ? (
                  <Button variant="contained" onClick={onEditLocker}>
                    Edit locker
                  </Button>
                ) : (
                  <Button variant="contained" onClick={onAddLocker}>
                    Add locker
                  </Button>
                )}
                {/* <Button variant="contained" onClick={onAddLocker}>
                  Add locker
                </Button> */}
                <Button variant="outlined" onClick={() => {
                  setShowLockerModal(false);
                  setAddLockerError('');
                  setLockerStartDate(null);
                  setLockerEndDate(null);
                  setLockerComment('');
                  setLockerEditID(null)
                  setShowLockerModal(false)
                }}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </ModalComponent>
        </TableContainer>

        {/* *****************Dynamic Pricing********************** */}

        <Box component="form" sx={{ marginTop: '20px' }}>
          <h2>Dynamic Pricing</h2>

          <Table>
            <TableHeader headLabel={DYNAMIC_PRICE_HEADER} />
            <TableBody>
              {chaletDynamicPrices?.map((locker) => {
                const { _id, date, price, priceType, description } = locker;
                return (
                  <TableRow key={_id}>
                    <TableCell align="left">{refactorDateWithTime(date)}</TableCell>
                    <TableCell align="left">{price}</TableCell>
                    <TableCell align="left">{priceType}</TableCell>
                    <TableCell align="left">{description}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <ModalComponent isOpen={showDynamicPriceModal}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date desktop"
                  inputFormat="MM/DD/YYYY"
                  value={dynamicPriceDate}
                  onChange={(date) => setDynamicPriceDate(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <TextField
                required
                id="filled-search"
                type="number"
                placeholder="Price"
                name="discount"
                value={dynamicPrice}
                onChange={(e) => setDynamicPrice(e.target.value)}
              />
              <TextField
                required
                id="filled-search"
                type="text"
                placeholder="Description"
                name="desc"
                value={dynamicPriceDescription}
                onChange={(e) => setDynamicPriceDescription(e.target.value)}
              />
              <FormControl fullWidth sx={{ margin: '15px 0' }}>
                <InputLabel id="demo-simple-select-autowidth-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select"
                  value={dynamicPriceType}
                  label="Type"
                  onChange={(e) => setDynamicPriceType(e.target.value)}
                  name="cityId"
                >
                  <MenuItem value="custom">
                    <em>Custom</em>
                  </MenuItem>
                  <MenuItem value="weekend">
                    <em>Weekend</em>
                  </MenuItem>
                </Select>
              </FormControl>
              {dynamicPriceError?.length > 0 && (
                <Typography sx={{ color: 'red' }} component="p">
                  {dynamicPriceError}
                </Typography>
              )}
              <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px', justifyContent: 'flex-end' }}>
                <Button variant="contained" onClick={handleAddDynamicPrice}>
                  Add Price
                </Button>
                <Button variant="outlined" onClick={() => setShowDynamicPriceModal(false)}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </ModalComponent>
        </Box>
        <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
          <Button variant="contained" onClick={() => setShowDynamicPriceModal(true)}>
            Add Price
          </Button>
        </Box>
      </Box>
    </div >
  );
};

export default SingleChalet;
