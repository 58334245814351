import apiClient from "src/shared/api"

export const getUsers = (page = 1) => {
    const params = {
        'page': page,
        'pageSize': 20,
    }

    const urlParams = new URLSearchParams(params).toString();

    return apiClient.get(`/users/?${urlParams}`)
        .then(res => res.data)
        .catch(err => err.response);
}

// export const addRule = (data) => {
//     return apiClient.put(`/chaletAttributes/chaletRoles`, data)
// }

// export const deleteRule = (data) => {
//     return apiClient.delete('/chaletAttributes/chaletRoles', {
//         data
//     })
// }

// export const editRule = (data) => {
//     return apiClient.post('/chaletAttributes/chaletRoles', data)
// }