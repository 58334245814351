import axios from 'axios';
import { logoutUser } from 'src/api/auth/login';

const apiClient = () => {

    const defaultOptions = {
        baseURL: process.env.REACT_APP_BASE_API_URL,
    }

    const instance = axios.create(defaultOptions)

    // Add a request interceptor
    instance.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            Promise.reject(error);
        },
    );

    //Add a response interceptor
    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response.status === 401) {
                logoutUser()
            }
            return Promise.reject(error);
        },
    );

    return instance;
}

export default apiClient();


// axios.create({
//     baseURL: 'http://34.245.136.210/api/v1/admin',
// })
    // const defaultOptions = ;

    // const instance = axios.create(defaultOptions)


    // Add a request interceptor
    // instance.interceptors.request.use(
    //     (config) => {
    //         const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzMzA3Yzg2ZGNjYTI0NjFiYjdlYTYxMCIsInJvbGUiOiI2MzMwN2M4M2RjY2EyNDYxYmI3ZWE1ZmIiLCJpYXQiOjE2NjQyMjExODAsImV4cCI6MTY2NDIyMjA4MH0.79LU6PzrmAjej67k-eTenQfc-pI1zWA7jdfznky-9co';
    //         if (token) {
    //             config.headers.Authorization = `bearer token`;
    //         }
    //         return config;
    //     },
    //     (error) => {
    //         Promise.reject(error);
    //     },
    // );

    //Add a response interceptor
    // instance.interceptors.response.use(
    //     (response) => {
    //         return response;
    //     },
    //     (error) => {
    //         //   if (error.response.status === 401) {
    //         //     Router.push({ pathname: '/login' }, undefined, { shallow: true });
    //         //     return Promise.reject(error);
    //         //   }
    //         return Promise.reject(error);
    //     },
    // );

//     return instance;
// };
