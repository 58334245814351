/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
// eslint-disable-next-line import/no-named-as-default
import TableHeader from '../components/shared/Table/TableHeader';

import ActionsMenu from 'src/components/shared/ActionsMenu';
import ModalComponent from 'src/components/shared/Modal';
import Form from 'src/components/shared/Form';
import { addChaletView, deleteChaletView, editChaletView, getChaletViews } from 'src/api/views';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'nameAr', label: 'Name Arabic', alignRight: false },
  { id: 'nameEn', label: 'Name English', alignRight: false },
  { id: 'deleted', label: 'Deleted', alignRight: false },
];

const Views = () => {
  const [chaletViews, setChaletViews] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [showAddModal, setShowAddModal] = useState(false);
  const [error, setError] = useState('');
  const [editChaletViewData, setEditChaletViewData] = useState(null);

  const fetchChaletViews = async () => {
    const { data } = await getChaletViews(page);
    setChaletViews(data.views);
    setTotal(data.total);
  };

  useEffect(() => {
    fetchChaletViews();
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSubmit = (values) => {
    if (editChaletViewData) {
      const data = {
        name: values.nameEn,
        name_ar: values.nameAr,
        viewId: editChaletViewData._id,
      };
      editChaletView(data)
        .then((res) => {
          fetchChaletViews();
          setEditChaletViewData(null);
          setShowAddModal(false);
        })
    } else {
      const data = {
        name: values.nameEn,
        name_ar: values.nameAr,
      };
      addChaletView(data)
        .then((res) => {
          fetchChaletViews();
          setShowAddModal(false);
          setError('');
        })
        .catch((err) => setError(err.response.data.message));
    }
  };

  const onDeleteChaletView = (id) => {
    let data = {
      viewId: id,
    };
    deleteChaletView(data).then((res) => {
      fetchChaletViews();
    });
  };

  const onEditChaletView = (view) => {
    setEditChaletViewData(view);
    setShowAddModal(true);
  };

  return (
    <Page title="Chalet Views">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Chalet Views
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => setShowAddModal(true)}
          >
            Add Chalet View
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHeader headLabel={TABLE_HEAD} />
                <TableBody>
                  {chaletViews?.map((view) => {
                    const { _id, name, name_ar, hide } = view;

                    return (
                      <TableRow hover key={_id}>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{name_ar}</TableCell>
                        <TableCell align="left">
                          {hide ? (
                            <Typography sx={{ color: 'red' }} component="p">
                              Deleted
                            </Typography>
                          ) : (
                            ''
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <ActionsMenu
                            onDelete={() => onDeleteChaletView(_id)}
                            onEdit={() => onEditChaletView(view)}
                            deleted={hide}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            component="div"
            count={total}
            rowsPerPage={20}
            rowsPerPageOptions={[]}
            page={page}
            onPageChange={handleChangePage}
          />
        </Card>

        <ModalComponent isOpen={showAddModal}>
          <Form
            onCancel={() => {
              setShowAddModal(false);
              setEditChaletViewData(null);
            }}
            onSubmit={handleSubmit}
            error={error}
            editChaletType={editChaletViewData}
          />
        </ModalComponent>
      </Container>
    </Page>
  );
};

export default Views;
