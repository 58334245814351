import apiClient from "src/shared/api"

export const getResorts = (page = 1) => {
    const params = {
        'page': page,
        'pageSize': 20,
    }

    const urlParams = new URLSearchParams(params).toString();

    return apiClient.get(`/resort?${urlParams}`)
        .then(res => res.data)
        .catch(err => err.response);
}

export const addResort = (data) => {
    return apiClient.put(`/resort`, data)
        .then(res => res.data)
        .catch(err => err.response)
}

export const deleteResort = (data) => {
    return apiClient.delete(`/resort`, {
        data
    })
}