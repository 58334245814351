/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
// eslint-disable-next-line import/no-named-as-default
import TableHeader from '../components/shared/Table/TableHeader';

import ActionsMenu from 'src/components/shared/ActionsMenu';
import { deleteChalet, getChalets, getChaletsApprovalStatus } from 'src/api/chalets';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'deleted', label: 'Deleted', alignRight: false },
];

const Chalets = ({ approval_status }) => {
  const [chalets, setChalets] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  console.log(approval_status);
  const fetchChalets = async () => {
    switch (approval_status) {
      case "all":
        const { data } = await getChalets(page, searchValue);
        setChalets(data.chalets);
        setTotal(data.total);
        break;
      case "approved":
      case "binding":
      case "disapproved":
        const { data: dataApproved } = await getChaletsApprovalStatus(page, approval_status);
        setChalets(dataApproved.chalets);
        setTotal(dataApproved.total);
        break;

      default:

        break;
    }

  };

  useEffect(() => {
    fetchChalets();
  }, [page, searchValue, approval_status]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onDeleteChalet = (id) => {
    let data = {
      chaletId: id,
    };
    deleteChalet(data).then(() => {
      fetchChalets();
    });
  };

  const navToAddChalet = () => {
    navigate('/dashboard/add-chalet');
  };

  const navToChaletPage = (id) => {
    navigate(`/dashboard/chalet/${id}`);
  };

  return (
    <Page title="Chalets">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Chalets
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={navToAddChalet}>
            Add Chalet
          </Button>
        </Stack>

        <Stack>
          <TextField
            required
            id="filled-search"
            type="text"
            label="Search"
            name="search"
            placeholder="Search by chalet title, description or chalet code"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              setPage(0);
            }}
          />
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHeader headLabel={TABLE_HEAD} />
                <TableBody>
                  {chalets?.map((chalet) => {

                    const { _id, title, location, hide, isActive, approved } = chalet;

                    return (
                      <TableRow hover key={_id} onClick={() => navToChaletPage(_id)} sx={{ cursor: 'pointer' }}>
                        <TableCell align="left">{title} - active:{isActive ? 'true' : 'false'} - approved:{approved ? 'true' : 'false'}</TableCell>
                        <TableCell align="left">{location}</TableCell>
                        <TableCell align="left">
                          {hide ? (
                            <Typography sx={{ color: 'red' }} component="p">
                              Deleted
                            </Typography>
                          ) : (
                            ''
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <ActionsMenu
                            onDelete={() => onDeleteChalet(_id)}
                            deleted={hide}
                            onClickMore={() => navToChaletPage(_id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            component="div"
            count={total}
            rowsPerPage={20}
            rowsPerPageOptions={[]}
            page={page}
            onPageChange={handleChangePage}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default Chalets;
