import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getCities } from 'src/api/cities';
import { capitalizeString } from 'src/utils/formatString';

const ResortsForm = ({ onCancel, onSubmit, error, editChaletType }) => {
  const [formValues, setFormValues] = useState({
    nameAr: '',
    nameEn: '',
    cityId: '',
  });
  const [cities, setCities] = useState([]);

  const fetchCities = async () => {
    const { data } = await getCities();
    setCities(data.cities);
  };

  useEffect(() => {
    fetchCities();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: capitalizeString(value),
    });
  };

  const handleSubmit = () => {
    onSubmit(formValues);
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-autowidth-label">City</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select"
            value={formValues.cityId}
            label="City"
            onChange={handleChange}
            name="cityId"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {cities.map((city) => (
              <MenuItem value={city._id}>{city?.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          required
          id="filled-search"
          type="text"
          label="Name Arabic"
          name="nameAr"
          value={formValues.nameAr}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="text"
          label="Name English"
          name="nameEn"
          value={formValues.nameEn}
          onChange={handleChange}
        />
        {error.length > 0 && (
          <Typography sx={{ color: 'red' }} component="p">
            {error}
          </Typography>
        )}
      </div>
      <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px', justifyContent: 'flex-end' }}>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          {editChaletType ? 'Edit' : 'Submit'}
        </Button>
      </Box>
    </Box>
  );
};

export default ResortsForm;
