/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
// eslint-disable-next-line import/no-named-as-default
import TableHeader from '../components/shared/Table/TableHeader';

import { addChaletType, deleteChaletType, editChaletType, getChaletTypes } from 'src/api/chaletTypes';
import ActionsMenu from 'src/components/shared/ActionsMenu';
import ModalComponent from 'src/components/shared/Modal';
import Form from 'src/components/shared/Form';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'nameAr', label: 'Name Arabic', alignRight: false },
  { id: 'nameEn', label: 'Name English', alignRight: false },
  { id: 'deleted', label: 'Deleted', alignRight: false },
];

const ChaletTypes = () => {
  const [chaletTypes, setChaletTypes] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [error, setError] = useState('');
  const [editChaletTypeData, setEditChaletTypeData] = useState(null);

  const fetchChaletTypes = async () => {
    const { data } = await getChaletTypes(page);
    setChaletTypes(data.types);
    setTotal(data.total);
  };

  useEffect(() => {
    fetchChaletTypes();
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleSubmit = (values) => {
    if (editChaletTypeData) {
      const data = {
        name: values.nameEn,
        name_ar: values.nameAr,
        typeId: editChaletTypeData._id,
      };
      editChaletType(data)
        .then((res) => {
          fetchChaletTypes();
          setEditChaletTypeData(null);
          setShowAddModal(false);
        })
    } else {
      const data = {
        name: values.nameEn,
        name_ar: values.nameAr,
      };
      addChaletType(data)
        .then((res) => {
          fetchChaletTypes();
          setShowAddModal(false);
          setError('');
        })
        .catch((err) => setError(err.response.data.message));
    }
  };

  const onDeleteChaletType = (id) => {
    let data = {
      typeId: id,
    };
    deleteChaletType(data).then((res) => {
      fetchChaletTypes();
    });
  };

  const onEditChaletType = (type) => {
    setEditChaletTypeData(type);
    setShowAddModal(true);
  };

  return (
    <Page title="Chalet types">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Chalet Types
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => setShowAddModal(true)}
          >
            Add Chalet Type
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHeader headLabel={TABLE_HEAD} />
                <TableBody>
                  {chaletTypes?.map((type) => {
                    const { _id, name, name_ar, hide } = type;

                    return (
                      <TableRow hover key={_id}>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{name_ar}</TableCell>
                        <TableCell align="left">
                          {hide ? (
                            <Typography sx={{ color: 'red' }} component="p">
                              Deleted
                            </Typography>
                          ) : (
                            ''
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <ActionsMenu
                            onDelete={() => onDeleteChaletType(_id)}
                            onEdit={() => onEditChaletType(type)}
                            deleted={hide}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            component="div"
            count={total}
            rowsPerPage={20}
            rowsPerPageOptions={[]}
            page={page}
            onPageChange={handleChangePage}
          />
        </Card>

        <ModalComponent isOpen={showAddModal}>
          <Form
            onCancel={() => {
              setShowAddModal(false);
              setEditChaletTypeData(null);
            }}
            onSubmit={handleSubmit}
            error={error}
            editChaletType={editChaletTypeData}
          />
        </ModalComponent>
      </Container>
    </Page>
  );
};

export default ChaletTypes;
