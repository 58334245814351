import { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Typography } from '@mui/material';
import { capitalizeString } from 'src/utils/formatString';

const Form = ({ onCancel, onSubmit, error, editChaletType }) => {
  const [formValues, setFormValues] = useState({
    nameAr: editChaletType ? editChaletType.name_ar : '',
    nameEn: editChaletType ? editChaletType.name : '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: capitalizeString(value),
    });
  };

  const handleSubmit = () => {
    onSubmit(formValues);
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          required
          id="filled-search"
          type="text"
          label="Name Arabic"
          name="nameAr"
          value={formValues.nameAr}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="text"
          label="Name English"
          name="nameEn"
          value={formValues.nameEn}
          onChange={handleChange}
        />
        {error.length > 0 && (
          <Typography sx={{ color: 'red' }} component="p">
            {error}
          </Typography>
        )}
      </div>
      <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px', justifyContent: 'flex-end' }}>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          {editChaletType ? 'Edit' : 'Submit'}
        </Button>
      </Box>
    </Box>
  );
};

export default Form;
