import { Navigate, useRoutes } from 'react-router-dom';
import AddImages from './components/shared/AddImages';
import useLocalStorage from './hooks/useLocalStorage';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import AddChalet from './pages/AddChalet';
//
import Chalets from './pages/Chalets';
import ChaletTypes from './pages/ChaletTypes';
import Cities from './pages/Cities';
import Facilities from './pages/Facilities';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Reservations from './pages/Reservations';
import Resorts from './pages/Resorts';
import Rules from './pages/Rules';
import SingleChalet from './pages/SingleChalet/SingleChalet';
import SingleReservation from './pages/SingleReservation/SingleReservation';
import Users from './pages/Users';
import Views from './pages/Views';

// ----------------------------------------------------------------------

export default function Router() {
  let routes = [
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/login" /> },
      ],
    }
  ]

  if (localStorage.getItem('token')) {
    routes = [
      {
        path: '/dashboard',
        element: <DashboardLayout />,
        children: [
          { path: 'chalets', element: <Chalets approval_status={"all"} /> },
          { path: 'chaletsapproved', element: <Chalets approval_status={"approved"} /> },
          { path: 'chaletsrequests', element: <Chalets approval_status={"binding"} /> },
          { path: 'chaletsdisapproved', element: <Chalets approval_status={"disapproved"} /> },
          { path: 'chalet-types', element: <ChaletTypes /> },
          { path: 'cities', element: <Cities /> },
          { path: 'views', element: <Views /> },
          { path: 'resorts', element: <Resorts /> },
          { path: 'facilities', element: <Facilities /> },
          { path: 'rules', element: <Rules /> },
          { path: 'reservations', element: <Reservations /> },
          { path: 'users', element: <Users /> },
          { path: 'reservation/:id', element: <SingleReservation /> },
          { path: 'add-chalet', element: <AddChalet /> },
          { path: 'chalet/:id', element: <SingleChalet /> },
          { path: 'chalet/:id/images', element: <AddImages /> },
        ],
      },
      {
        path: '/',
        element: <LogoOnlyLayout />,
        children: [
          { path: '/', element: <Navigate to="/dashboard/chalets" /> },
          { path: '404', element: <NotFound /> },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: '/login',
        element: <Navigate to="/dashboard/chalet-types" replace />,
      },
      {
        path: '*',
        element: <Navigate to="/404" replace />,
      },
    ]
  }

  return useRoutes(routes);
}


