/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
// eslint-disable-next-line import/no-named-as-default
import TableHeader from '../components/shared/Table/TableHeader';

import ActionsMenu from 'src/components/shared/ActionsMenu';
import ModalComponent from 'src/components/shared/Modal';
import { addResort, deleteResort, getResorts } from 'src/api/resorts';
import ResortsForm from 'src/components/ResortsForm';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'nameAr', label: 'Name Arabic', alignRight: false },
  { id: 'nameEn', label: 'Name English', alignRight: false },
  { id: 'city', label: 'City', alignRight: false },
  { id: 'deleted', label: 'Deleted', alignRight: false },
];

const Resorts = () => {
  const [resorts, setResorts] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [showAddModal, setShowAddModal] = useState(false);
  const [error, setError] = useState('');

  const fetchResorts = async () => {
    const { data } = await getResorts(page);
    setResorts(data.resorts);
    setTotal(data.total);
  };

  useEffect(() => {
    fetchResorts();
  }, [page]);

  const handleChangePage = (event, newPage) => {
    // setPage(newPage + 1);
    setPage(newPage);
  };

  const handleSubmit = (values) => {
    const data = {
      name: values.nameEn,
      name_ar: values.nameAr,
      city_id: values.cityId,
    };
    addResort(data)
      .then((res) => {
        fetchResorts();
        setShowAddModal(false);
        setError('');
      })
      .catch((err) => setError(err.response.data.message));
  };

  const onDeleteChaletType = (id) => {
    let data = {
      resortId: id,
    };
    deleteResort(data).then((res) => {
      fetchResorts();
    });
  };

  return (
    <Page title="Resorts">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Resorts
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => setShowAddModal(true)}
          >
            Add Resort
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHeader headLabel={TABLE_HEAD} />
                <TableBody>
                  {resorts?.map((type) => {
                    const { _id, name, name_ar, hide } = type;

                    return (
                      <TableRow hover key={_id}>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{name_ar}</TableCell>
                        <TableCell align="left">
                          {hide ? (
                            <Typography sx={{ color: 'red' }} component="p">
                              Deleted
                            </Typography>
                          ) : (
                            ''
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <ActionsMenu onDelete={() => onDeleteChaletType(_id)} onEdit={null} deleted={hide} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            component="div"
            count={total}
            rowsPerPage={20}
            rowsPerPageOptions={[]}
            page={page}
            onPageChange={handleChangePage}
          />
        </Card>

        <ModalComponent isOpen={showAddModal}>
          <ResortsForm
            onCancel={() => {
              setShowAddModal(false);
            }}
            onSubmit={handleSubmit}
            error={error}
          />
        </ModalComponent>
      </Container>
    </Page>
  );
};

export default Resorts;
