import apiClient from "src/shared/api"

export const getChaletViews = (page = 1) => {
    const params = {
        'page': page,
        'pageSize': 20,
    }

    const urlParams = new URLSearchParams(params).toString();

    return apiClient.get(`/views?${urlParams}`)
        .then(res => res.data)
        .catch(err => err.response);
}

export const addChaletView = (data) => {
    return apiClient.put(`/views`, data)
}

export const deleteChaletView = (data) => {
    return apiClient.delete('/views', {
        data
    })
}

export const editChaletView = (data) => {
    return apiClient.post('/views', data)
}