import PropTypes from 'prop-types';
// material
import { TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';


const TableHeader = ({
  headLabel,
}) => (
  <TableHead>
    <TableRow>
      {headLabel.map((headCell) => (
        <TableCell key={headCell.id} >
          <TableSortLabel>
            {headCell.label}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
)

TableHeader.propTypes = {
  headLabel: PropTypes.array,
};

export default TableHeader;
