/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
// material
import {
    Card,
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
// eslint-disable-next-line import/no-named-as-default
import TableHeader from '../components/shared/Table/TableHeader';

import { getUsers } from 'src/api/users';
import ActionsMenu from 'src/components/shared/ActionsMenu';
import ModalComponent from 'src/components/shared/Modal';
import Form from 'src/components/shared/Form';

// ----------------------------------------------------------------------
// authMethod
// : 
// "local"
// createdAt
// : 
// "2022-11-20T16:19:23.946Z"
// email
// : 
// "test@test.com"
// firstName
// : 
// "test"
// lastName
// : 
// "test"
// phoneNumber
// : 
// "01254698725"
// updatedAt
// : 
// "2022-11-20T16:19:23.946Z"
// __v
// : 
// 0
// _id
// : 
// "637a538bc808d1bfd24c2842"
const TABLE_HEAD = [
    // 
    { id: 'authMethod', label: 'Auth Method', alignRight: false },
    { id: 'createdAt', label: 'Created At', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'firstName', label: 'First Name', alignRight: false },
    { id: 'lastName', label: 'Last Name', alignRight: false },
    { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
    { id: 'updatedAt', label: 'Updated At', alignRight: false },
    { id: '_id', label: '_id', alignRight: false },
];

const Users = () => {
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [showAddModal, setShowAddModal] = useState(false);
    const [error, setError] = useState('');
    const [editFacilityData, setEditFacilityData] = useState(null);

    const fetchUsers = async () => {
        const { data } = await getUsers(page);
        console.log(data);
        setUsers(data.users);
        setTotal(data.total);
    };

    useEffect(() => {
        fetchUsers();
    }, [page]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // const handleSubmit = (values) => {
    //     if (editFacilityData) {
    //         const data = {
    //             name: values.nameEn,
    //             name_ar: values.nameAr,
    //             attributeId: editFacilityData._id,
    //         };
    //         editFacility(data)
    //             .then((res) => {
    //                 fetchFacilities();
    //                 setEditFacilityData(null);
    //                 setShowAddModal(false);
    //             })
    //     } else {
    //         const data = {
    //             name: values.nameEn,
    //             name_ar: values.nameAr,
    //         };
    //         addFacility(data)
    //             .then((res) => {
    //                 fetchFacilities();
    //                 setShowAddModal(false);
    //                 setError('');
    //             })
    //             .catch((err) => setError(err.response.data.message));
    //     }
    // };

    // const onDeleteFacility = (id) => {
    //     let data = {
    //         typeId: id,
    //     };
    //     deleteFacility(data).then((res) => {
    //         fetchFacilities();
    //     });
    // };

    // const onEditChaletType = (type) => {
    //     setEditFacilityData(type);
    //     setShowAddModal(true);
    // };

    return (
        <Page title="Facilities">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Users
                    </Typography>
                    {/* <Button
                        variant="contained"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={() => setShowAddModal(true)}
                    >
                        Add Facility
                    </Button> */}
                </Stack>

                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <TableHeader headLabel={TABLE_HEAD} />
                                <TableBody>
                                    {users?.map((user) => {
                                        const { authMethod,
                                            createdAt,
                                            email,
                                            firstName,
                                            lastName,
                                            phoneNumber,
                                            updatedAt,
                                            _id, } = user
                                        return (
                                            <TableRow hover key={_id}>
                                                <TableCell align="left">{authMethod}</TableCell>
                                                <TableCell align="left">{createdAt}</TableCell>
                                                <TableCell align="left">{email}</TableCell>
                                                <TableCell align="left">{firstName}</TableCell>
                                                <TableCell align="left">{lastName}</TableCell>
                                                <TableCell align="left">{phoneNumber}</TableCell>
                                                <TableCell align="left">{updatedAt}</TableCell>
                                                <TableCell align="left">{_id}</TableCell>
                                                {/* <TableCell align="left">
                                                    {hide ? (
                                                        <Typography sx={{ color: 'red' }} component="p">
                                                            Deleted
                                                        </Typography>
                                                    ) : (
                                                        ''
                                                    )}
                                                </TableCell> */}
                                                {/* <TableCell align="right">
                                                    <ActionsMenu
                                                        onDelete={() => onDeleteFacility(_id)}
                                                        onEdit={() => onEditChaletType(facility)}
                                                        deleted={hide}
                                                    />
                                                </TableCell> */}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        component="div"
                        count={total}
                        rowsPerPage={20}
                        rowsPerPageOptions={[]}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </Card>

                {/* <ModalComponent isOpen={showAddModal}>
                    <Form
                        onCancel={() => {
                            setShowAddModal(false);
                            setEditFacilityData(null);
                        }}
                        onSubmit={handleSubmit}
                        error={error}
                        editChaletType={editFacilityData}
                    />
                </ModalComponent> */}
            </Container>
        </Page>
    );
};

export default Users;
