import apiClient from "src/shared/api"

export const loginUser = async ({
    email,
    password,
    setToken,
    setError,
}) => {
    try {
        const userCredentials = {
            email: email,
            password: password,
        }
        const { data } = await apiClient.post(`/auth/login`, userCredentials);
        const authToken = data.data.access_token;
        setToken(authToken);

        return;
    } catch (error) {
        setError(error.response?.data?.message);
        throw error.response;
    }
};

export const logoutUser = async () => {
    return (
        await window.localStorage.removeItem('token')
    );
};