export const mapChaletUiToData = (data) => {
    return {
        long: data.long,
        lat: data.lat,
        location: data.location,
        street: data.street,
        building: data.building,
        discount: data.discount,
        markup: data.markup,
        price: data.price,
        area: data.area,
        rooms_numbers: data.roomsNumber,
        beds_numbers: data.bedsNumber,
        bathrooms_numbers: data.bathroomsNumber,
        floor_numbers: data.floorsNumber,
        unit_number: data.unitNumber,
        capacity: data.capacity,
        description: data.description,
        description_ar: data.descriptionAr,
        title: data.title,
        title_ar: data.titleAr,
        Check_in: data.checkIn,
        Check_out: data.checkOut,
        city_id: data.cityId,
        resort_id: data.resortId,
        type_id: data.typeId,
        // roles: data.rules,
        roles: data.roles,
        facilities: data.facilities,
        views: data.views,
        approval_status: data.approval_status,
        isActive: data.isActive,

    }
}