import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Autocomplete, Button, TextareaAutosize, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getCities } from 'src/api/cities';
import { getResorts } from 'src/api/resorts';
import { getChaletTypes } from 'src/api/chaletTypes';
import { LocalizationProvider, TimePicker } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { addOrEditChalet } from 'src/api/chalets';
import { mapChaletUiToData } from 'src/utils/chalet';
import { getRules } from 'src/api/rules';
import { getFacilities } from 'src/api/facilities';

const ChaletForm = ({ onCancel }) => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    title: '',
    titleAr: '',
    location: '',
    street: '',
    building: '',
    lat: 0,
    long: 0,
    discount: 0,
    markup: 0,
    price: 0,
    area: 0,
    roomsNumber: 0,
    bedsNumber: 0,
    bathroomsNumber: 0,
    floorsNumber: 0,
    unitsNumber: 0,
    capacity: 0,
    description: '',
    descriptionAr: '',
    checkIn: '',
    checkOut: '',
    cityId: '',
    resortId: '',
    typeId: '',
    rules: [],
    facilities: [],
  });
  const [cities, setCities] = useState([]);
  const [resorts, setResorts] = useState([]);
  const [types, setTypes] = useState([]);
  const [rules, setRules] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [error, setError] = useState('');

  const fetchCities = async () => {
    const { data } = await getCities();
    setCities(data.cities);
  };

  const fetchResorts = async () => {
    const { data } = await getResorts();
    setResorts(data.resorts);
  };

  const fetchChaletTypes = async () => {
    const { data } = await getChaletTypes();
    setTypes(data.types);
  };

  const fetchRules = async () => {
    const { data } = await getRules();
    setRules(data.chaletRoles);
  };

  const fetchFacilities = async () => {
    const { data } = await getFacilities();
    setFacilities(data.facility);
  };

  useEffect(() => {
    fetchCities();
    fetchResorts();
    fetchChaletTypes();
    fetchRules();
    fetchFacilities();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleMultiSelectChange = (values, name) => {
    let newValues = values.map((value) => value._id);
    setFormValues({
      ...formValues,
      [name]: newValues,
    });
  };

  const handleSubmit = (values) => {
    addOrEditChalet(mapChaletUiToData(formValues))
      .then((res) => {
        setError('');
        const { _id } = res.data.data.chalet;
        navigate(`/dashboard/chalet/${_id}/images`);
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
    // }
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
        overFlow: 'scroll',
        padding: '10px',
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          required
          id="filled-search"
          type="text"
          label="Title"
          name="title"
          value={formValues.title}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="text"
          label="Title Arabic"
          name="titleAr"
          value={formValues.titleAr}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="text"
          label="Location"
          name="location"
          value={formValues.location}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="text"
          label="Street"
          name="street"
          value={formValues.street}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="text"
          label="Building"
          name="building"
          value={formValues.building}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="number"
          label="Latitude"
          name="lat"
          value={formValues.lat}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="number"
          label="Langitude"
          name="long"
          value={formValues.long}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="number"
          label="Discount"
          name="discount"
          value={formValues.discount}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="number"
          label="Markup"
          name="markup"
          value={formValues.markup}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="number"
          label="Price"
          name="price"
          value={formValues.price}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="number"
          label="Markup in %"
          name="markup"
          value={formValues.markup}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="number"
          label="Area"
          name="area"
          value={formValues.area}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="number"
          label="Rooms Number"
          name="roomsNumber"
          value={formValues.roomsNumber}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="number"
          label="Beds Number"
          name="bedsNumber"
          value={formValues.bedsNumber}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="number"
          label="Bathrooms Number"
          name="bathroomsNumber"
          value={formValues.bathroomsNumber}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="number"
          label="Floors Number"
          name="floorsNumber"
          value={formValues.floorsNumber}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="number"
          label="Units Number"
          name="unitNumber"
          value={formValues.unitNumber}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-search"
          type="number"
          label="Capacity"
          name="capacity"
          value={formValues.capacity}
          onChange={handleChange}
        />
        <InputLabel id="demo-simple-select-autowidth-label">Description</InputLabel>
        <TextareaAutosize
          required
          id="filled-search"
          type="test"
          label="Description"
          name="description"
          value={formValues.description}
          onChange={handleChange}
          minRows={4}
        />
        <InputLabel id="demo-simple-select-autowidth-label">Description Arabic</InputLabel>
        <TextareaAutosize
          required
          id="filled-search"
          type="test"
          label="Description Arabic"
          name="descriptionAr"
          value={formValues.descriptionAr}
          onChange={handleChange}
          minRows={4}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="Check In"
            value={formValues.checkIn}
            onChange={(value) => {
              setFormValues({
                ...formValues,
                checkIn: value.$d,
              });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <TimePicker
            label="Check Out"
            value={formValues.checkOut}
            onChange={(value) => {
              setFormValues({
                ...formValues,
                checkOut: value.$d,
              });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <FormControl fullWidth sx={{ margin: '15px 0' }}>
          <InputLabel id="demo-simple-select-autowidth-label">City</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select"
            value={formValues.cityId}
            label="City"
            onChange={handleChange}
            name="cityId"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {cities?.map((city) => (
              <MenuItem value={city._id}>{city?.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: '15px' }}>
          <InputLabel id="demo-simple-select-autowidth-label">Resort</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select"
            value={formValues.resortId}
            label="Resort"
            onChange={handleChange}
            name="resortId"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {resorts?.map((resort) => (
              <MenuItem value={resort._id}>{resort?.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-autowidth-label">Chalet Types</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select"
            value={formValues.typeId}
            label="Chalet Type"
            onChange={handleChange}
            name="typeId"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {types?.map((type) => (
              <MenuItem value={type._id}>{type?.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={rules}
          getOptionLabel={(option) => option.name}
          defaultValue={[]}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} label="Rules" placeholder="Rules" />}
          onChange={(e, value) => handleMultiSelectChange(value, 'rules')}
        />
        <Autocomplete
          multiple
          id="tags-outlined"
          options={facilities}
          getOptionLabel={(option) => option.name}
          defaultValue={[]}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} label="Facilities" placeholder="Facilities" />}
          onChange={(e, value) => handleMultiSelectChange(value, 'facilities')}
        />
        {error?.length > 0 && (
          <Typography sx={{ color: 'red' }} component="p">
            {error}
          </Typography>
        )}
      </div>
      <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px', justifyContent: 'flex-end' }}>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default ChaletForm;
