import apiClient from "src/shared/api"

export const getChalets = (page, search) => {
    const params = {
        'page': page + 1,
        'pageSize': 20,
        ...(search && { searchQ: search })
    }

    const urlParams = new URLSearchParams(params).toString();

    return apiClient.get(`/chalets?${urlParams}`)
        .then(res => res.data)
        .catch(err => err.response);
}
// /api/v1/admin/chalets/listed/requests
export const getChaletsApprovalStatus = (page, approval_status) => {
    const params = {
        'page': page + 1,
        'pageSize': 20,
        'approval_status': approval_status
    }
    const urlParams = new URLSearchParams(params).toString();

    return apiClient.get(`/chalets/listed/requests?${urlParams}`)
        .then(res => res.data)
        .catch(err => err.response);
}

export const getSingleChalet = (id) => {
    return apiClient.get(`/chalets/${id}`);
}

export const addOrEditChalet = (data) => {
    return apiClient.post(`/chalets`, data)
}

export const deleteChalet = (data) => {
    return apiClient.delete('/chalets', {
        data
    })
}

export const editChalet = (data) => {
    return apiClient.post('/chalets', data)
}

export const editChaletStatus = (data) => {
    return apiClient.post('/chalets/listed/requests', data)
}

export const editChaletActivity = (data) => {
    return apiClient.post('/chalets/activity', data)
}

export const uploadChaletImages = (data) => {
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }

    return apiClient.post('/chalets/images', data, config);
}

export const deleteChaletImage = (src, id) => {
    return apiClient.delete(`/chalets/image`, {
        data: {
            chaletId: id,
            imageSrc: src
        }
    })
}

export const chooseDefaultChaletImage = (src, id) => {
    return apiClient.post(`/chalets/image`, {
        chaletId: id,
        imageSrc: src
        // data: {
        //     chaletId: id,
        //     imageSrc: src
        // }
    })
}

export const addDynamicPrice = (data) => {
    return apiClient.post('dynamicPrice', data)
}

export const getDynamicPrice = (id) => {
    return apiClient.get(`/dynamicPrice?chalet=${id}`)
}

export const getChaletLockers = (id) => {
    return apiClient.get(`/chaletLocker?chaletId=${id}`)
}

export const addChaletLocker = (data) => {
    return apiClient.post(`/chaletLocker`, data)
}

export const editChaletLocker = (data) => {
    return apiClient.post(`/chaletLocker/edit`, data)
}

export const deleteChaletLocker = (data) => {
    console.log(data);
    return apiClient.delete(`/chaletLocker`, { data })
    // return apiClient.delete()
}
