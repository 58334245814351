import { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const Gallery = ({ images }) => {
  const [imagesArray, setImagesArray] = useState([])

  useEffect(() => {
    if (images.length) {
      const items = images.map(image => ({ original: image.original.src, thumbnail: image.thumbnail.src, defaultImage: image.original.defaultImage }))
      setImagesArray(items)
    }
  }, [images])
  console.log(imagesArray);

  return (<ImageGallery
    showPlayButton={false}
    autoPlay={false}
    useBrowserFullscreen={false}
    sizes={{ width: "100px" }}
    disableThumbnailScroll={true}
    items={imagesArray}
  />)
}

export default Gallery