// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'all chalets',
    path: '/dashboard/chalets',
    icon: getIcon('ant-design:home-outlined'),
  },
  {
    title: 'chalets approved',
    path: '/dashboard/chaletsapproved',
    icon: getIcon('ant-design:home-outlined'),
  },
  {
    title: 'chalets requests',
    path: '/dashboard/chaletsrequests',
    icon: getIcon('ant-design:home-outlined'),
  },
  {
    title: 'chalets disapproved',
    path: '/dashboard/chaletsdisapproved',
    icon: getIcon('ant-design:home-outlined'),
  },
  {
    title: 'chalet types',
    path: '/dashboard/chalet-types',
    icon: getIcon('gridicons:types'),
  },
  {
    title: 'cities',
    path: '/dashboard/cities',
    icon: getIcon('bytesize:location'),
  },
  {
    title: 'views',
    path: '/dashboard/views',
    icon: getIcon('ep:view'),
  },
  {
    title: 'resorts',
    path: '/dashboard/resorts',
    icon: getIcon('carbon:home'),
  },
  {
    title: 'facilities',
    path: '/dashboard/facilities',
    icon: getIcon('bi:cart-plus'),
  },
  {
    title: 'rules',
    path: '/dashboard/rules',
    icon: getIcon('carbon:subnet-acl-rules'),
  },
  {
    title: 'reservations',
    path: '/dashboard/reservations',
    icon: getIcon('carbon:subnet-acl-rules'),
  },
  {
    title: 'users',
    path: '/dashboard/users',
    icon: getIcon('material-symbols:person-2'),
  }
];

export default navConfig;
