import apiClient from "src/shared/api"

export const getReservations = (page, search) => {
    const params = {
        'page': page,
        'pageSize': 20,
        ...(search && { chalet: search })
    }

    const urlParams = new URLSearchParams(params).toString();
    console.log(urlParams);
    return apiClient.get(`/reservation?${urlParams}`)
        .then(res => res.data)
        .catch(err => err.response);
}

// export const getSingleChalet = (id) => {
//     return apiClient.get(`/chalets/${id}`);
// }

// export const addOrEditChalet = (data) => {
//     return apiClient.post(`/chalets`, data)
// }

// export const deleteChalet = (data) => {
//     return apiClient.delete('/chalets', {
//         data
//     })
// }

// export const editChalet = (data) => {
//     return apiClient.post('/chalets', data)
// }

// export const uploadChaletImages = (data) => {
//     const config = {
//         headers: { 'content-type': 'multipart/form-data' }
//     }

//     return apiClient.post('/chalets/images', data, config);
// }

// export const deleteChaletImage = (src, id) => {
//     return apiClient.delete(`/chalets/image`, {
//         data: {
//             chaletId: id,
//             imageSrc: src
//         }
//     })
// }

// export const addDynamicPrice = (data) => {
//     return apiClient.post('dynamicPrice', data)
// }

// export const getChaletLockers = (id) => {
//     return apiClient.get(`/chaletLocker?chaletId=${id}`)
// }

// export const addChaletLocker = (data) => {
//     return apiClient.post(`/chaletLocker`, data)
// }
