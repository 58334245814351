import apiClient from "src/shared/api"

export const getCities = (page = 1) => {
    const params = {
        'page': page,
        'pageSize': 20,
    }

    const urlParams = new URLSearchParams(params).toString();

    return apiClient.get(`/city?${urlParams}`)
        .then(res => res.data)
        .catch(err => err.response);
}

export const addCity = (data) => {
    return apiClient.post(`/city`, data)
}

export const deleteCity = (data) => {
    return apiClient.delete('/city', {
        data
    })
}
