export const mapDataToUi = (data) => {
    return {
        title: data.title,
        titleAr: data.title_ar,
        location: data.location,
        code: data.code,
        lat: data.lat,
        long: data.long,
        street: data.street,
        building: data.building,
        discount: data.discount,
        markup: data.markup,
        price: data.price,
        area: data.area,
        roomsNumber: data.rooms_numbers,
        bedsNumber: data.beds_numbers,
        bathroomsNumber: data.bathrooms_numbers,
        floorsNumber: data.floor_numbers,
        unitNumber: data.unit_number,
        capacity: data.capacity,
        description: data.description,
        descriptionAr: data.description_ar,
        checkIn: data.Check_in,
        checkOut: data.Check_out,
        city: data.city_id.name,
        cityId: data.city_id._id,
        resort: data.resort_id.name,
        resortId: data.resort_id._id,
        type: data.type_id.name,
        typeId: data.type_id._id,
        images: data.images.map(image => (
            {
                original: image,
                thumbnail: image
            }
        )),
        facilities: data.facilities,
        views: data.views,
        // rules: data.roles
        roles: data.roles,
        approval_status: data.approval_status,
        isActive: data.isActive,
    }
}