import apiClient from "src/shared/api"

export const getChaletTypes = (page = 0) => {
    const params = {
        'page': page + 1,
        'pageSize': 20,
    }

    const urlParams = new URLSearchParams(params).toString();

    return apiClient.get(`/chaletTypes?${urlParams}`)
        .then(res => res.data)
        .catch(err => err.response);
}

export const addChaletType = (data) => {
    return apiClient.put(`/chaletTypes`, data)
}

export const deleteChaletType = (data) => {
    return apiClient.delete('/chaletTypes', {
        data
    })
}

export const editChaletType = (data) => {
    return apiClient.post('/chaletTypes', data)
}