/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
// eslint-disable-next-line import/no-named-as-default
import TableHeader from '../components/shared/Table/TableHeader';

import { addFacility, deleteFacility, editFacility, getFacilities } from 'src/api/facilities';
import ActionsMenu from 'src/components/shared/ActionsMenu';
import ModalComponent from 'src/components/shared/Modal';
import Form from 'src/components/shared/Form';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'nameAr', label: 'Name Arabic', alignRight: false },
  { id: 'nameEn', label: 'Name English', alignRight: false },
  { id: 'deleted', label: 'Deleted', alignRight: false },
];

const Facilities = () => {
  const [facilities, setFacilities] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [error, setError] = useState('');
  const [editFacilityData, setEditFacilityData] = useState(null);

  const fetchFacilities = async () => {
    const { data } = await getFacilities(page);
    setFacilities(data.facility);
    setTotal(data.total);
  };

  useEffect(() => {
    fetchFacilities();
  }, [page]);

  const handleChangePage = (event, newPage) => {
    // setPage(newPage + 1);
    setPage(newPage);
  };

  const handleSubmit = (values) => {
    if (editFacilityData) {
      const data = {
        name: values.nameEn,
        name_ar: values.nameAr,
        attributeId: editFacilityData._id,
      };
      editFacility(data)
        .then((res) => {
          fetchFacilities();
          setEditFacilityData(null);
          setShowAddModal(false);
        })
    } else {
      const data = {
        name: values.nameEn,
        name_ar: values.nameAr,
      };
      addFacility(data)
        .then((res) => {
          fetchFacilities();
          setShowAddModal(false);
          setError('');
        })
        .catch((err) => setError(err.response.data.message));
    }
  };

  const onDeleteFacility = (id) => {
    let data = {
      typeId: id,
    };
    deleteFacility(data).then((res) => {
      fetchFacilities();
    });
  };

  const onEditChaletType = (type) => {
    setEditFacilityData(type);
    setShowAddModal(true);
  };

  return (
    <Page title="Facilities">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Facilities
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => setShowAddModal(true)}
          >
            Add Facility
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHeader headLabel={TABLE_HEAD} />
                <TableBody>
                  {facilities?.map((facility) => {
                    const { _id, name, name_ar, hide } = facility;

                    return (
                      <TableRow hover key={_id}>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{name_ar}</TableCell>
                        <TableCell align="left">
                          {hide ? (
                            <Typography sx={{ color: 'red' }} component="p">
                              Deleted
                            </Typography>
                          ) : (
                            ''
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <ActionsMenu
                            onDelete={() => onDeleteFacility(_id)}
                            onEdit={() => onEditChaletType(facility)}
                            deleted={hide}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            component="div"
            count={total}
            rowsPerPage={20}
            rowsPerPageOptions={[]}
            page={page}
            onPageChange={handleChangePage}
          />
        </Card>

        <ModalComponent isOpen={showAddModal}>
          <Form
            onCancel={() => {
              setShowAddModal(false);
              setEditFacilityData(null);
            }}
            onSubmit={handleSubmit}
            error={error}
            editChaletType={editFacilityData}
          />
        </ModalComponent>
      </Container>
    </Page>
  );
};

export default Facilities;
