/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    TextField,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
// eslint-disable-next-line import/no-named-as-default
import TableHeader from '../components/shared/Table/TableHeader';

import ActionsMenu from 'src/components/shared/ActionsMenu';
import { getReservations } from 'src/api/reservations';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'paymobOrderId', label: 'Paymob Order Id', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'phone', label: 'Phone', alignRight: false },
    { id: 'totalPrice', label: 'Total Price', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'createdAt', label: 'Created At', alignRight: false },
    { id: 'chale', label: 'Chalet Id', alignRight: false },
    { id: 'user', label: 'User Id', alignRight: false },
    // { id: 'deleted', label: 'Deleted', alignRight: false },
    // { id: 'actions', label: 'Actions', alignRight: false },
];

const Reservations = () => {
    const [reservations, setReservations] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const navigate = useNavigate();

    const fetchReservations = async () => {
        const { data } = await getReservations(page, searchValue);
        setReservations(data.reservations);
        setTotal(data.total);
    };

    useEffect(() => {
        fetchReservations();
    }, [page, searchValue]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const onDeleteChalet = (id) => {
        let data = {
            chaletId: id,
        };
        // deleteChalet(data).then(() => {
        //     fetchReservations();
        // });
    };

    const navToAddReservation = () => {
        navigate('/dashboard/add-chalet');
    };

    const navToReservationPage = (id) => {
        navigate(`/dashboard/reservation/${id}`);
    };

    const navToChalePage = (id) => {
        navigate(`/dashboard/chalet/${id}`);
    };

    return (
        <Page title="Reservations">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Reservations
                    </Typography>
                    {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={navToAddReservation}>
                        Add Reservation
                    </Button> */}
                </Stack>

                <Stack>
                    <TextField
                        required
                        id="filled-search"
                        type="text"
                        label="Search"
                        name="search"
                        placeholder="Search by chalet code"
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                            setPage(0);
                        }}
                    />
                </Stack>

                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <TableHeader headLabel={TABLE_HEAD} />
                                <TableBody>
                                    {reservations?.map((reservation) => {
                                        const { _id,
                                            paymobOrderId,
                                            email,
                                            phone,
                                            total_Price,
                                            status,
                                            createdAt,
                                            chalet,
                                            user,
                                        } = reservation;

                                        return (
                                            <TableRow hover key={_id}
                                                // onClick={() => navToReservationPage(_id)} 
                                                sx={{ cursor: 'pointer' }}>
                                                <TableCell align="left">{paymobOrderId}</TableCell>
                                                <TableCell align="left">{email}</TableCell>
                                                <TableCell align="left">{phone}</TableCell>
                                                <TableCell align="left">{total_Price}</TableCell>
                                                <TableCell align="left">{status}</TableCell>
                                                <TableCell align="left">{createdAt}</TableCell>
                                                <TableCell align="left"><Link to={`/dashboard/chalet/${chalet}`}>{chalet}</Link></TableCell>
                                                <TableCell align="left">{user}</TableCell>
                                                <TableCell align="left">
                                                    {!_id ? (
                                                        <Typography sx={{ color: 'red' }} component="p">
                                                            Deleted
                                                        </Typography>
                                                    ) : (
                                                        ''
                                                    )}
                                                </TableCell>
                                                {/* <TableCell align="right">
                                                    <ActionsMenu
                                                        onDelete={() => onDeleteChalet(_id)}
                                                        deleted={_id}
                                                        onClickMore={() => navToReservationPage(_id)}
                                                    />
                                                </TableCell> */}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        component="div"
                        count={total}
                        rowsPerPage={20}
                        rowsPerPageOptions={[]}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </Card>
            </Container>
        </Page>
    );
};

export default Reservations;
